import CardContent from "@mui/material/CardContent"
import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"

import { Card } from "@mui/material"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useAddPickupsScheduleMutation, useGetAdditionalFieldsQuery } from "./slice"

import CarrierServiceLogo from "../../../../../carrier-service-logo"
import convertTZ from "../../../../../../utils/convert-timezone"
import DrawerAlert from "../../../../../drawer-alert"
import PickupAdditionalFields from "./components/pickup-additional-fields"
import restApiSlice from "../../../../../../services/rest-api/slice"
import TimeZoneDateTimeSelector from "../../../../../timezone-datetime-selector"
import TransactionStatus, { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"

import { clearDrawerAlert } from "../../../../../../reducers/drawerAlertReducer"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"

import DateTimeService from "../../../../../../services/date-time"
import PickupConfirmationNumberCard from "../pickup-details-drawer/components/pickup-confirmation-number-card"

function SchedulePickupDrawer({ carrier, address, showSchedulePickupDrawer, setShowSchedulePickupDrawer, trackingId }) {
  const { t } = useTranslation("shipments")
  const dispatch = useDispatch()

  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const { data: carrierAdditionalFields } = useGetAdditionalFieldsQuery(carrier.code)
  const [addPickupsSchedule, { data: addPickupSheduleResponse, isLoading, isError }] = useAddPickupsScheduleMutation()

  const handleClose = () => {
    if (showTransactionStatus && addPickupSheduleResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS) dispatch(restApiSlice.util.invalidateTags(["PendingShipments"]))
    setShowSchedulePickupDrawer(false)
  }

  const addTenMinutes = (date) => {
    return new Date(date.setMinutes(date.getMinutes() + 10))
  }

  const subtractOneHour = (date) => {
    return new Date(date.setHours(date.getHours() - 1))
  }

  const formik = useFormik({
    initialValues: {
      pickupDate: useMemo(() => {
        return new Date(convertTZ(new Date(), address.timezone))
      }, []),
      readyTime: useMemo(() => {
        return new Date(convertTZ(new Date(), address.timezone))
      }, []),
      untilTime: useMemo(() => {
        return new Date(convertTZ(DateTimeService.addHours(new Date(), 2), address.timezone))
      }, []),
      timezone: address.timezone,
    },
    validate: (formValues) => {
      const errors = {}
      const currentDateAndTimeInSelectedTimezone = new Date(convertTZ(new Date(), formValues.timezone))
      const currentDateInSelectedTimezone = new Date(convertTZ(new Date(), formValues.timezone))
      currentDateInSelectedTimezone.setHours(0, 0, 0, 0)
      const endOfCurrentDateInSelectedTimezone = new Date(convertTZ(new Date(), formValues.timezone))
      endOfCurrentDateInSelectedTimezone.setHours(23, 59, 59, 999)

      const pickupDateInSelectedTimezone = new Date(formValues.pickupDate)

      const { readyTime } = formValues
      readyTime.setFullYear(pickupDateInSelectedTimezone.getFullYear())
      readyTime.setMonth(pickupDateInSelectedTimezone.getMonth())
      readyTime.setDate(pickupDateInSelectedTimezone.getDate())
      readyTime.setSeconds(0, 0)
      const readyTimePlusTenMinutes = addTenMinutes(new Date(readyTime))

      // To test untilTIme, let's pretend it's local time and use currentDateInSelectedTimezone
      const localUntilTime = formValues.untilTime
      localUntilTime.setFullYear(currentDateInSelectedTimezone.getFullYear())
      localUntilTime.setMonth(currentDateInSelectedTimezone.getMonth())
      localUntilTime.setDate(currentDateInSelectedTimezone.getDate())
      localUntilTime.setSeconds(0, 0)
      const localReadyTime = formValues.readyTime
      localReadyTime.setFullYear(currentDateInSelectedTimezone.getFullYear())
      localReadyTime.setMonth(currentDateInSelectedTimezone.getMonth())
      localReadyTime.setDate(currentDateInSelectedTimezone.getDate())
      localReadyTime.setSeconds(0, 0)
      const localUntilTimeMinusAnHour = subtractOneHour(new Date(localUntilTime))

      const untilTime = new Date(convertTZ(formValues.untilTime, formValues.timezone))
      untilTime.setFullYear(pickupDateInSelectedTimezone.getFullYear())
      untilTime.setMonth(pickupDateInSelectedTimezone.getMonth())
      untilTime.setDate(pickupDateInSelectedTimezone.getDate())
      untilTime.setSeconds(0, 0)

      // Check if pickup date is before today (in the selected timezone)
      if (formValues.pickupDate < currentDateInSelectedTimezone) errors.pickupDate = true
      else {
        // Check if ready time is before current time (in the selected timezone)
        if (readyTimePlusTenMinutes < currentDateAndTimeInSelectedTimezone) errors.readyTime = true
        // Check if until time is before the ready time (in the current timezone)
        if (localUntilTimeMinusAnHour < localReadyTime) errors.untilTime = true
      }

      carrierAdditionalFields?.forEach((additionalField) => {
        if (additionalField.validations?.isRequired && formValues.additionalFields && !formValues.additionalFields[additionalField.name]) {
          errors[additionalField.name] = t("schedulePickupDrawer.additionalFields.error.required")
        }
      })

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const additionalFields = {}
      if (formValues.additionalFields) {
        Object.keys(formValues.additionalFields).forEach((key) => {
          additionalFields[key] = formValues.additionalFields[key] || undefined
        })
      }
      const pickupsSchedule = {
        pickupDate: new Date(formValues.pickupDate).toLocaleString("sv-SE").split(" ")[0],
        readyTime: new Date(formValues.readyTime).toLocaleString("sv-SE").split(" ")[1],
        untilTime: new Date(formValues.untilTime).toLocaleString("sv-SE").split(" ")[1],
        timezone: formValues.timezone,
        additionalFields,
        carrierCode: carrier.code,
        carrierDivision: carrier.division,
        carrierAccount: carrier.carrierAccount,
        address,
        trackingId,
      }
      setShowTransactionStatus(true)
      await addPickupsSchedule(pickupsSchedule)
    },
  })

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  useEffect(() => {
    dispatch(clearDrawerAlert())
  }, [])

  return (
    <SoeDrawer showDrawer={showSchedulePickupDrawer} setShowDrawer={setShowSchedulePickupDrawer} anchor="right" keepMounted={false} preventClosing={isLoading}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("schedulePickupDrawer.title")}
          setShowDrawer={() => {
            setShowSchedulePickupDrawer(false)
          }}
          preventClosing={isLoading}
        />
        <SoeDrawerContent drawerScrollToTop={showTransactionStatus}>
          <DrawerAlert />
          {showTransactionStatus && <TransactionStatus transaction={addPickupSheduleResponse} title={t("schedulePickupDrawer.add.label")} isError={isError} isLoading={isLoading} />}
          {showTransactionStatus && addPickupSheduleResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS && <PickupConfirmationNumberCard confirmationNumber={addPickupSheduleResponse?.metadata?.confirmationNumber} />}
          <Card
            sx={(theme) => ({
              mb: theme.spacing(2),
            })}
          >
            <CardContent
              sx={(theme) => ({
                "&:last-child": {
                  pb: theme.spacing(2),
                },
              })}
            >
              <CarrierServiceLogo code={carrier.code} logo={carrier.logo} align="left" />
            </CardContent>
          </Card>
          <form onSubmit={formik.handleSubmit} noValidate>
            <TimeZoneDateTimeSelector formik={formik} displayTimeZone={Intl.DateTimeFormat().resolvedOptions().timeZone !== address.timezone} shipperTimeZone={address.timezone} displayPickupDate displayReadyTime displayUntilTime />
            {carrierAdditionalFields?.length > 0 &&
              <Card sx={(theme) => ({ marginTop: theme.spacing(2), marginBottom: theme.spacing(2), position: "relative", overview: "hidden" })}>
                <CardContent
                  sx={(theme) => ({
                    "&:last-child": {
                      py: theme.spacing(1),
                    },
                  })}
                >
                  <Grid container>
                    <PickupAdditionalFields formik={formik} carrier={carrier} />
                  </Grid>
                </CardContent>
              </Card>
            }
          </form>
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={
            !(addPickupSheduleResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS)
              ? [
                {
                  action: handleClose,
                  label: t("schedulePickupDrawer.actions.close"),
                  variant: "outlined",
                  disabled: isLoading,
                },
                {
                  action: handleSubmit,
                  label: t("schedulePickupDrawer.actions.ok"),
                  variant: "contained",
                  loading: isLoading,
                },
              ]
              : [
                {
                  action: handleClose,
                  label: t("schedulePickupDrawer.actions.close"),
                  variant: "contained",
                  disabled: isLoading,
                },
              ]
          }
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

SchedulePickupDrawer.propTypes = {
  carrier: PropTypes.instanceOf(Object).isRequired,
  showSchedulePickupDrawer: PropTypes.bool.isRequired,
  setShowSchedulePickupDrawer: PropTypes.func.isRequired,
  address: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    postalCode: PropTypes.string,
    provinceCode: PropTypes.string,
    timezone: PropTypes.string,
  }).isRequired,
  trackingId: PropTypes.string,
}

SchedulePickupDrawer.defaultProps = {
  trackingId: "",
}

export default SchedulePickupDrawer
