// REACT
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// Formik
import { useFormik } from "formik"

// Others
import AppConfig from "../../../../../../utils/app-config"
import convertTZ from "../../../../../../utils/convert-timezone"
import FullAddressForm from "../../../../../full-address-form"
import TransactionStatus, { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"

import { getAddressFormat, isRequiredField } from "../../../../../../services/address-form"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { updateShipper, updateShippingOptions } from "../../../../../../reducers/quotationsReducer"
import { useAddAddressBookItemMutation, useEditAddressBookItemMutation } from "../../../../../address-book/slice"
import { useValidateAddressMutation } from "../../../../../full-address-form/slice"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../services/address-form/slice"

function RateRequestShipper({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation("fullAddressForm")

  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.user)
  const { shipper, shippingOptions } = useSelector((state) => state.quotations)

  const [action, setAction] = useState("")
  const [saveAddressChecked, setSaveAddressChecked] = useState(false)
  const [selectedAddressFromAutoComplete, setSelectedAddressFromAutoComplete] = useState({})
  const [saveDefaultAddressChecked, setSaveDefaultAddressChecked] = useState(false)
  const [saveDefaultAddressDisabled] = useState(false)
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const [addAddressBookItem, { data: newAddressBookItem }] = useAddAddressBookItemMutation()
  const [editAddressBookItem, { data: updatedAddressBookItem }] = useEditAddressBookItemMutation()
  const [validateAddress, { data: validationAddress, isLoading, isError }] = useValidateAddressMutation()

  useEffect(() => {
    if (newAddressBookItem?.data) {
      let newAddressBook = { ...shipper, ...newAddressBookItem.data }
      if (newAddressBookItem.data.canadaPostId) newAddressBook = { ...newAddressBook, canadaPostId: newAddressBookItem.data.canadaPostId }
      dispatch(updateShipper(newAddressBook))
    }
  }, [newAddressBookItem])

  useEffect(() => {
    if (updatedAddressBookItem?.data?.canadaPostId) {
      dispatch(updateShipper({ ...shipper, canadaPostId: updatedAddressBookItem.data.canadaPostId }))
    }
  }, [updatedAddressBookItem])

  const formik = useFormik({
    initialValues: {
      id: shipper?.id || undefined,
      companyName: shipper?.companyName || "",
      personName: shipper?.personName || "",
      addressLine1: shipper?.addressLine1 || "",
      addressLine2: shipper?.addressLine2 || "",
      city: shipper?.city || "",
      provinceName: shipper?.provinceName || "",
      provinceCode: shipper?.provinceCode || "",
      countryCode: shipper?.countryCode || currentUser?.company?.clientAddress?.countryCode || currentUser?.clientAddress?.countryCode || "CA",
      postalCode: shipper?.postalCode || "",
      residential: shipper?.residential || false,
      phoneNumber: shipper?.phoneNumber || "",
      emailAddress: shipper?.emailAddress || "",
      timezone: shipper?.timezone || undefined,
      validationProviderId: shipper?.validationProviderId || undefined,
    },
    validate: async (formValues) => {
      const errors = {}

      if (!formValues.personName.trim()) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber.trim()) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      const { timezone, ...addressToValidate } = formValues
      setShowTransactionStatus(true)
      validateAddress(addressToValidate)
    },
  })

  useEffect(() => {
    if (validationAddress?.status === TRANSACTION_STATUS_ENUM.SUCCESS) {
      const temporaryShipper = { ...formik.values, ...validationAddress?.metadata?.address }

      dispatch(updateShipper(temporaryShipper))
      if (shippingOptions && shippingOptions.shippingDateTimezone !== Intl.DateTimeFormat().resolvedOptions()?.timeZone) {
        dispatch(updateShippingOptions({ ...shippingOptions, shippingDate: convertTZ(new Date(), temporaryShipper?.timezone).split(" ")[0], shippingDateTimezone: temporaryShipper?.timezone }))
      }
      if ((selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.origin === "CanadaPost") || (selectedAddressFromAutoComplete && selectedAddressFromAutoComplete.canadaPostId) || formik.values.canadaPostId) {
        if (selectedAddressFromAutoComplete.origin === "CanadaPost") formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.id }
        if (selectedAddressFromAutoComplete.canadaPostId) formik.values = { ...formik.values, canadaPostId: selectedAddressFromAutoComplete.canadaPostId }
        if (formik.values.canadaPostId) formik.values = { ...formik.values, canadaPostId: formik.values.canadaPostId }
      }

      if (saveAddressChecked || saveDefaultAddressChecked) {
        if (saveDefaultAddressChecked) formik.values = { ...formik.values, isDefaultShipper: true }
        if (saveAddressChecked && !saveDefaultAddressChecked && formik.values?.id === currentUser.defaultShipperId) delete formik.values.id
        if (formik.values.id) {
          editAddressBookItem({ id: formik.values.id, payload: formik.values })
        } else {
          addAddressBookItem(formik.values)
        }
      }
      if (action === "continue") {
        setShowTransactionStatus(false)
        wizardProps.nextStep()
      }
      if (action === "saveAndClose") {
        setShowRateRequestDrawer(false)
        setShowTransactionStatus(false)
      }
    }
  }, [validationAddress])

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  useEffect(() => {
    formik.setErrors({})
    formik.setTouched({})
  }, [formik.values.countryCode])

  const getDisplaySaveAddressCheckbox = () => {
    if (formik.values?.id && formik.values.id === currentUser.defaultShipperId && !formik.dirty) return false
    return true
  }

  const getDisplaySaveDefaultAddressCheckbox = () => {
    if (formik.values?.id && formik.values.id === currentUser.defaultShipperId && !formik.dirty) return false
    return true
  }

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.shipper.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent drawerScrollToTop={showTransactionStatus}>
        {AppConfig.features.showAddressValidation && showTransactionStatus && <TransactionStatus transaction={validationAddress} title={t("drawer.shipper.validateAddress.label", { ns: "rateRequest" })} isError={isError} isLoading={isLoading} />}
        <FullAddressForm
          formik={formik}
          displaySearchAddressBar
          displayResidentialToggle
          displaySaveAddressCheckbox={getDisplaySaveAddressCheckbox()}
          displaySaveDefaultAddressCheckbox={getDisplaySaveDefaultAddressCheckbox()}
          saveAddressCheckboxLabel={formik.values.id && formik.values.id !== currentUser.defaultShipperId ? t("shipper.updateAddressCheckbox.label") : t("shipper.saveAddressCheckbox.label")}
          saveDefaultAddressCheckboxLabel={formik.values.id && formik.values.id === currentUser.defaultShipperId ? t("shipper.updateDefaultAddressCheckbox.label") : t("shipper.saveDefaultAddressCheckbox.label")}
          setSelectedAddressFromAutoComplete={setSelectedAddressFromAutoComplete}
          saveAddressChecked={saveAddressChecked}
          setSaveAddressChecked={setSaveAddressChecked}
          saveDefaultAddressChecked={saveDefaultAddressChecked}
          setSaveDefaultAddressChecked={setSaveDefaultAddressChecked}
          saveDefaultAddressDisabled={saveDefaultAddressDisabled}
        />
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          wizardProps.currentStep === wizardProps.totalSteps
            ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.shipper.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
            : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.shipper.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.shipper.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
        }
      />
    </SoeDrawerContainer>
  )
}

RateRequestShipper.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestShipper.defaultProps = {}

export default RateRequestShipper
