// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

// import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import { useDispatch } from "react-redux"

import restApiSlice from "../../../../../../services/rest-api/slice"
import Stripe from "../../../../../quotations/components/payment/components/payment-method/components/stripe"

import { useGetPaymentIntentQuery } from "../../../../../../reducers/paymentReducer"

function NewPaymentMethodDrawer({ onSubmitEvent, setShowDrawer, setIsLoadingPaymentMethod }) {
  const dispatch = useDispatch()
  const customTheme = useTheme()

  const { data: paymentIntent } = useGetPaymentIntentQuery()

  const handleSaveButton = async () => {
    setIsLoadingPaymentMethod(true)
    document.getElementById("payment-element").dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
    setTimeout(() => {
      setIsLoadingPaymentMethod(false)
      dispatch(restApiSlice.util.invalidateTags(["UserProfile"]))
      setShowDrawer(false)
    }, 6000)
  }

  useEffect(() => {
    if (onSubmitEvent) {
      handleSaveButton()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <Box component="div" my={customTheme.utils.pxToRem(10)}>
        {paymentIntent && <Stripe paymentIntent={paymentIntent} />}
      </Box>
    </Box>
  )
}

NewPaymentMethodDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

NewPaymentMethodDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowDrawer: PropTypes.func.isRequired,
  setIsLoadingPaymentMethod: PropTypes.func.isRequired,
}

export default NewPaymentMethodDrawer
