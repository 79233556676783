import restApiSlice from "../rest-api/slice"
import store from "../../store"
import { fetchUserDetailsSuccess, updateUserDetailsSuccess } from "../../reducers/userReducer"
import { updateUserAccountStatusSuccess } from "../../reducers/companyReducer"

const userRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: () => ({ url: "/users/me", method: "GET" }),
      providesTags: ["user"],
      transformResponse: (response) => {
        store.dispatch(fetchUserDetailsSuccess(response.data))
        return response.data
      },
    }),
    editUserProfile: builder.mutation({
      query: (payload) => ({ url: "/users/me", method: "PATCH", body: payload }),
      invalidatesTags: ["UserProfile"],
      transformResponse: (response) => {
        store.dispatch(updateUserDetailsSuccess(response.data))
        return response.data
      },
    }),
    updateOrgUser: builder.mutation({
      query: (payload) => ({ url: "/users/me", method: "PATCH", body: payload }),
      transformResponse: (response) => {
        return response.data
      },
    }),
    deleteUserWebsocket: builder.mutation({
      query: (connectionId) => ({ url: `/users/me/websocket/${connectionId}`, method: "DELETE" }),
    }),
    disableUser: builder.mutation({
      query: ({ userId }) => ({ url: `/users/${userId}/disable`, method: "PUT"}),
      transformResponse: (response) => {
        store.dispatch(updateUserAccountStatusSuccess({ 
          userId: response?.data?.pk,
          disabled: response?.data?.disabled,
        }))
        return response.data
      },
    }),
    enableUser: builder.mutation({
      query: ({ userId }) => ({ url: `/users/${userId}/enable`, method: "PUT"}),
      transformResponse: (response) => {
        store.dispatch(updateUserAccountStatusSuccess({ 
          userId: response?.data?.pk,
          disabled: response?.data?.disabled,
        }))

        return response.data
      },
    })
  }),
})

export const { useDeleteUserWebsocketMutation, useEditUserProfileMutation, useGetUserProfileQuery, useUpdateOrgUserMutation, useDisableUserMutation, useEnableUserMutation } = userRestApiSlice

export default userRestApiSlice.reducer
